export const OTP_STATUS = {
  INITIAL: "inital",
  SENDING: "sending",
  SENT: "sent",
  NOT_SENT: "not_sent",
};

export const OTP_VERIFICATION_STATUS = {
  INITIAL: "initial",
  VERIFYING: "verifying",
  VERIFIED: "verified",
  NOT_VERIFIED: "not_verified",
};

export const LOADING_STATUS = {
  INITIAL: "initial",
  LOADING: "loading",
  LOADED: "loaded",
  FAILED: "failed",
};

export const SAVING_STATUS = {
  INITIAL: "initial",
  SENDING: "sending",
  SAVED: "saved",
  FAILED: "failed",
};

export const BODY_TYPE = {
  Open: "OPEN",
  "Low Deck": "LOW_DECK",
  "High Deck": "HIGH_DECK",
  "Closed Container": "CLOSED_CONTAINER",
};
export const BODY_MATERIAL = {
  Steel: "STEEL",
  Wood: "WOOD",
};
export const ENQUIRY_STATUS = {
  UNQUOTED: "UNQUOTED",
  QUOTED: "QUOTED",
  YOU_QUOTED: "YOU_QUOTED",
  BID_WON: "BID_WON",
  BID_LOST: "BID_LOST",
  ENQUIRY_REJECTED: "ENQUIRY_REJECTED",
  ENQUIRY_EXPIRED: "ENQUIRY_EXPIRED",
  ENQUIRY_IGNORED: "ENQUIRY_IGNORED",
  ENQUIRY_CLOSED: "ENQUIRY_CLOSED",
};

export const CUSTOMER_ENQUIRY_STATUS = {
  FINDING_PARTNER: "FINDING_PARTNER",
  OFFER_RECEIVED: "OFFER_RECEIVED",
  CONVERTED_TO_TRIP: "CONVERTED_TO_TRIP",
  ENQUIRY_EXPIRED: "ENQUIRY_EXPIRED",
  OFFER_EXPIRED: "OFFER_EXPIRED",
  ENQUIRY_REJECTED: "ENQUIRY_REJECTED",
  ENQUIRY_CLOSED: "ENQUIRY_CLOSED",
};

export const PAYMENT_METHOD_CONSTANTS = {
  FULL: "FULL",
  HALF: "HALF",
  ZERO: "ZERO",
};
export const OFFER_TYPE = {
  BEST_MATCH: "bestMatch",
  BEST_OFFER: "bestOffer",
};

// Vehicle number format AP12BS1234
export const VEHICLE_NUMBER_REGEX = /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{1,4}$/;

// Vehicle number format 22BH9999AA
export const BHARAT_VEHICLE_NUMBER_REGEX = /^[0-9]{2}[A-Z]{2}[0-9]{4}[A-Z]{2}$/;

export const ADMIN_QUOTE_STATUS = {
  ENQUIRY_INCOMPLETE: "Enquiry Incomplete",
  UNQUOTED: "Unquoted",
  QUOTED: "Quoted",
  NO_SERVICE: "No Service",
  CONVERTED_TO_TRIP: "Converted To Trip",
  ENQUIRY_EXPIRED: "Enquiry Expired",
  OFFER_EXPIRED: "Offer Expired",
  ENQUIRY_CANCELLED: "Enquiry Cancelled",
  ENQUIRY_REJECTED: "Enquiry Rejected",
};

export const ADMIN_QUOTE_STATUS_COLOR = {
  UNQUOTED: "#EA8611",
  QUOTED: "#1CA82E",
  NO_SERVICE: "#FF0707",
};

export const INDUSTRY_ITEMS = [
  "Steel",
  "Plywood",
  "Cement",
  "Garment & Textiles",
];

export const SPECIAL_FACTORS_ITEMS = [
  "Coil Beam/Well",
  "Steel plain platform",
  "Wood plain platform",
  "Side body must",
  "Container locks",
];

export const ACCESSORIES = [
  "Chains",
  "Tarpaulin",
  "Wood Blocks",
  "Belts",
  "Ropes",
];

export const LOADING_TYPE = ["Open top", "Close top", "Convertible"];

export const NO_OF_PIECES = ["Single", "Multiple"];

export const DIMENSION_UNITS = ["Feet", "Meter", "Millimetre"];

export const CATEGORY_ITEMS = [
  "Flat products",
  "Long products",
  "Other (Steel)",
];

export const ENQUIRY_STATUS_TO_TEXT_MAP = {
  BID_LOST:
    "You lost the bid because of higher quote. Try giving more competitive rates for next quotes",
  ENQUIRY_REJECTED: "LoadEazy has rejected the enquiry.",
  ENQUIRY_EXPIRED:
    "You did not respond to this enquiry in time. To win bids please send quote before expiry time.",
  ENQUIRY_IGNORED:
    "You ignored this enquiry. Try to update service routes to receive relevant enquiries",
  ENQUIRY_CLOSED: "Enquiry is closed by customer.",
};

export const TRANSPORTER_TRIP_STATUS = {
  BOOKING_ACCEPTED: "Upcoming",
  DRIVER_AT_PICKUP_LOCATION: "Upcoming",
  MATERIAL_LOADED: "Upcoming",
  TRUCK_IN_TRANSIT: "In Transit",
  TRIP_COMPLETED: "Completed",
  CANCELLED_BY_CUSTOMER: "Cancelled",
  CANCELLED_BY_TRANSPORTER: "Cancelled",
  CANCELLED_BY_ADMIN: "Cancelled",
};

export const TRANSPORTER_TRIP_STATUS_COLOR = {
  BOOKING_ACCEPTED: "#F27D29",
  DRIVER_AT_PICKUP_LOCATION: "#F27D29",
  MATERIAL_LOADED: "#F27D29",
  TRUCK_IN_TRANSIT: "#0C7F44",
  TRIP_COMPLETED: "#6D6D6D",
  CANCELLED_BY_CUSTOMER: "#B8B8B8",
  CANCELLED_BY_TRANSPORTER: "#B8B8B8",
  CANCELLED_BY_ADMIN: "#B8B8B8",
};

export const TRANSPORTER_PAYMENT_STATUS = {
  UNPAID: "Unsettled",
  PARTIALLY_PAID: "Unsettled",
  PAID: "Settled",
};

export const TRANSPORTER_PAYMENT_STATUS_COLOR = {
  UNPAID: "#F27D29",
  PARTIALLY_PAID: "#F27D29",
  PAID: "#0C7F44",
};

export const TRANSPORTER_TRIP_STATUS_ARRAY = [
  {
    label: "Upcoming",
    value: "BOOKING_ACCEPTED,DRIVER_AT_PICKUP_LOCATION,MATERIAL_LOADED",
  },
  { label: "InTransit", value: "TRUCK_IN_TRANSIT" },
  { label: "Completed", value: "TRIP_COMPLETED" },
  {
    label: "Cancelled",
    value: "CANCELLED_BY_CUSTOMER,CANCELLED_BY_TRANSPORTER,CANCELLED_BY_ADMIN",
  },
];

export const TRANSPORTER_PAYMENT_STATUS_ARRAY = [
  { label: "Unsettled", value: "UNPAID,PARTIALLY_PAID" },
  { label: "Settled", value: "PAID" },
];

export const TRANSPORTER_TRIP_STEPPER_LABELS = [
  { label: "Booking Accepted", description: "" },
  { label: "Pickup Location", description: "" },
  { label: "Loading", description: "" },
  { label: "InTransit", description: "" },
  { label: "Delivered", description: "" },
];

export const TRIP_STEPPER_STATES = {
  BOOKING_ACCEPTED: 1,
  DRIVER_AT_PICKUP_LOCATION: 2,
  MATERIAL_LOADED: 3,
  TRUCK_IN_TRANSIT: 4,
  TRIP_COMPLETED: 5,
};

export const CANCELLED_TRIP_STEPPER_STATES = {
  BOOKING_ACCEPTED: 0,
  CANCELLED_BY_CUSTOMER: 1,
  CANCELLED_BY_TRANSPORTER: 1,
  CANCELLED_BY_ADMIN: 1,
  DRIVER_AT_PICKUP_LOCATION: 2,
  MATERIAL_LOADED: 3,
  TRUCK_IN_TRANSIT: 4,
  TRIP_COMPLETED: 5,
};

export const CONFIRM_BOOKING_DIALOG_STEPS = {
  CONTACT_PERSON_ADDRESS: "CONTACT_PERSON_ADDRESS",
  CONFIRM_AND_PAY: "CONFIRM_AND_PAY",
  PICKUP_CONTACT_DETAILS: "PICKUP_CONTACT_DETAILS",
};

export const TRIP_STATUS = {
  BOOKING_ACCEPTED: "BOOKING_ACCEPTED",
  DRIVER_AT_PICKUP_LOCATION: "DRIVER_AT_PICKUP_LOCATION",
  MATERIAL_LOADED: "MATERIAL_LOADED",
  TRUCK_IN_TRANSIT: "TRUCK_IN_TRANSIT",
  TRIP_COMPLETED: "TRIP_COMPLETED",
  CANCELLED_BY_CUSTOMER: "CANCELLED_BY_CUSTOMER",
  CANCELLED_BY_TRANSPORTER: "CANCELLED_BY_TRANSPORTER",
  CANCELLED_BY_ADMIN: "CANCELLED_BY_ADMIN",
};

export const PAYMENT_STATUS = {
  PARTIALLY_PAID: "PARTIALLY_PAID",
  PAID: "PAID",
  CONFIRM_AND_PAY: "CONFIRM_AND_PAY",
  UNPAID: "UNPAID",
};

export const TRIP_STATUS_ARRAY = [
  {
    label: "BOOKING_ACCEPTED",
    value: "Upcoming",
    color: "red",
  },
  {
    label: "DRIVER_AT_PICKUP_LOCATION",
    value: "Upcoming",
    color: "red",
  },
  {
    label: "DRIVER_AT_DROPOFF_LOCATION",
    value: "Delivered",
    color: "green",
  },
  {
    label: "MATERIAL_LOADED",
    value: "Upcoming",
    color: "red",
  },
  {
    label: "TRUCK_IN_TRANSIT",
    value: "In Transit",
    color: "orange",
  },
  {
    label: "TRIP_COMPLETED",
    value: "Completed",
    color: "green",
  },
  {
    label: "CANCELLED_BY_CUSTOMER",
    value: "Cancelled",
    color: "grey",
  },
  {
    label: "CANCELLED_BY_TRANSPORTER",
    value: "Cancelled",
    color: "grey",
  },
  {
    label: "CANCELLED_BY_ADMIN",
    value: "Cancelled",
    color: "grey",
  },
];

export const PAYMENT_STATUS_ARRAY = [
  {
    label: "PARTIALLY_PAID",
    value: "Partial",
    color: "orange",
  },
  {
    label: "UNPAID",
    value: "Unpaid",
    color: "red",
  },
  {
    label: "PAID",
    value: "Paid",
    color: "green",
  },
];

export const PAYMENT_METHOD = ["upi", "cash", "card", "netbanking", "wallet"];

export const CANCELLED_TRIP_STATUS = [
  "CANCELLED_BY_CUSTOMER",
  "CANCELLED_BY_TRANSPORTER",
  "CANCELLED_BY_ADMIN",
];

export const UPCOMING_TRIP_STATUS = [
  "BOOKING_ACCEPTED",
  "DRIVER_AT_PICKUP_LOCATION",
  "MATERIAL_LOADED",
];

export const IN_TRANSIT_TRIP_STATUS = ["TRUCK_IN_TRANSIT"];

export const COMPLETED_TRIP_STATUS = ["TRIP_COMPLETED"];

export const USER_ROLES = ["Admin", "Transporter", "Customer"];
